import { useRouter } from 'next/router';
import toast from 'react-hot-toast/headless';

import { LoginLayout } from '@/components/shared-login/login-layout';
import type { LoginFormFields } from '@/pages/welcome/login/login-form';
import { LoginForm, isPostPageQuery } from '@/pages/welcome/login/login-form';
import { useClients } from '@/services/hooks/use-clients';

const LoginPage = () => {
    const router = useRouter();

    const { client } = useClients();

    const onSubmit = async (data: LoginFormFields) => {
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            body: JSON.stringify({ email: data.email, password: data.password }),
        });

        if (response.ok) {
            toast.success('Successfully logged in', {
                id: 'login_success',
            });

            setTimeout(() => {
                toast.dismiss('login_success');
                const query = isPostPageQuery(router.query) ? router.query : { r: '' };
                router.push(query.r ? query.r : '/event/launchpad');
            }, 1000);
        } else {
            toast.error('Failed to login. Please try again.');
        }
    };

    return (
        <LoginLayout>
            <div className="mb-9">
                <p className="mb-2 text-sm font-semibold leading-4 text-neutral-60">
                    Sign in to In2event account:
                </p>
                <h1 className="text-4xl font-black -tracking-4 text-neutral-200">
                    {client?.company.companyName ?? 'In2Event'}
                </h1>
            </div>
            <LoginForm onSubmitHandler={onSubmit} />
        </LoginLayout>
    );
};

export default LoginPage;
