import { Checkbox } from '@i2e/components';
import { FormControl, FormField, FormItem, FormLabel } from '@i2e/forms';
import type { ReactNode } from 'react';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

export interface CheckboxFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    children: ReactNode;
}

export const CheckboxLabel = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    children,
    ...props
}: CheckboxFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => (
                <FormItem className="flex items-center space-x-2 [&:not(:first-child)]:mt-2">
                    <FormControl>
                        <Checkbox checked={value as boolean} {...field} />
                    </FormControl>
                    <FormLabel className="text-neutral-250">{children}</FormLabel>
                </FormItem>
            )}
        />
    );
};
